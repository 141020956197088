import { cn } from '@hapstack/common'
import type { IconName } from '@hapstack/ui'
import { Card, CardContent, Icon } from '@hapstack/ui'
import type { ComponentProps } from 'react'

export const Metric = ({
  icon,
  className,
  children,
}: ComponentProps<'div'> & { icon?: IconName }) => (
  <Card className={className}>
    <CardContent className="flex flex-col justify-center">
      <div className="flex w-full items-center justify-between gap-4">
        <div>{children}</div>
        {icon ? (
          <Icon
            name={icon}
            size="xl"
            className="hidden text-muted-foreground opacity-70 md:block"
          />
        ) : null}
      </div>
    </CardContent>
  </Card>
)

export const MetricLabel = ({ className, children }: ComponentProps<'div'>) => (
  <div className={cn('text-sm font-medium text-muted-foreground', className)}>
    {children}
  </div>
)

export const MetricValue = ({ children, className }: ComponentProps<'div'>) => {
  return <div className={cn('text-2xl font-bold', className)}>{children}</div>
}

export const MetricDescription = ({
  className,
  children,
}: ComponentProps<'div'>) => (
  <div className={cn('text-xs text-muted-foreground', className)}>
    {children}
  </div>
)
